.App {
  background-color: #282c34;
  /* min-height: 100vh; */
}

/* .ant-card {
  margin-bottom: 20px !important;
  margin-top: 20px  !important;
  width: 100%;
} */

.ant-form-inline .ant-form-item {
  margin-bottom: 20px;
}

.ant-layout-header {
  /* height: 64px; */
  padding: 0;
  /* line-height: 64px; */
}

.ant-layout-content {
  padding: 0 !important;
  background: none !important;
  margin: 16px 16px !important;
}

.socialIcons {
  font-size: 300%;
}

.honuLogo {
  width: 100%;
}

.video{
  width: 100%;
  height: calc(100vw/1.77);
  position: relative;
 }

.logo {
  height: 32px;
  background-color: #babac01a;
  margin: 14px;
  border-radius: 10px;
}

.ant-btn-primary {
  background-color:  rgba(4, 208, 11, 0.4);
  border-color:  rgba(4, 208, 11, 0.4);
}

.ant-btn.ant-btn-primary:hover {
  /* border-color: red; */
  background:  rgba(110, 255, 115, 0.418) !important;
  /* border-style: dashed; */
}

.date{
  margin-left: auto;
  margin-right: 20px;
}

.ant-menu-item.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu> .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu> .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  color:  #282c34;
  /* border-bottom: 2px solid  white; */
  background: rgba(4, 208, 11, 0.4) !important;
}

.ant-image-preview-operations-operation:not(:first-child) {
  display: none;
}

/* .gif{
  width: 1%;
  height: 35px;
  margin-top: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; 
  margin: 100px;
} */

/* .gifSmall{
  width: 12%;
  height: auto;
} */

.btnPublish{
  margin-top: 5%;
}

/* .ant-btn{
  height: auto;
  height: auto;
  white-space: normal;
} */

.flexible-card .ant-card-body {
  flex-grow: 1
}

